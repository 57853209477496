<template>
  <b-card title="Create Awesome 🙌">
    <b-card-text>We will soon unlock this section and allow you to register applications in order to receive clientIDs and secrets. Stay tuned.</b-card-text>
    <b-button
      variant="primary"
      @click="callApi"
    >
      + New App
    </b-button>
    <pre>{{ devApps }}</pre>
    <!-- modal vertical center -->
    <b-modal
      id="New-App-Modal"
      ref="New-App-Modal"
      centered
      size="lg"
      title="New Dev App Created"
      ok-only
      ok-title="Close"
    >
      <b-card-text v-if="newAppData">
        <pre>{{ {"client_id": newAppData.dev_apps.client_id, "client_secret": newAppData.dev_apps.client_secret} }}</pre>
        {{ getApps() }}
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BModal,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BModal,
  },
  data() {
    return {
      newAppData: null,
      apiMessage: null,
      devApps: null,
    }
  },
  async mounted() {
    // Get the access token from the auth wrapper
    if (Object.keys(this.$auth.user).length > 0) {
      this.token = await this.$auth.getTokenSilently()
    }
    // Use Axios to make a call to the API
    // eslint-disable-next-line dot-notation
    this.getApps()
  },
  methods: {
    async getApps() {
      this.token = await this.$auth.getTokenSilently()
      const { data } = await axios.get('http://localhost:3000/dev/apps', {
        headers: {
          Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
        },
      })
      this.devApps = data
    },
    showModal() {
      this.$refs['New-App-Modal'].show()
    },
    async callApi() {
      // Get the access token from the auth wrapper

      // Use Axios to make a call to the API
      // eslint-disable-next-line dot-notation
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      const { data } = await axios.post('http://localhost:3000/dev/apps', {
      })
      this.newAppData = data
      this.showModal()
    },
  },
}
</script>

<style>

</style>
